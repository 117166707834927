import React, { useEffect, useState } from "react";
import "../../styles/MainPage/PartnersMain.css";
import axios from "axios";
import { read } from "fs";
import partnersData, {Sponsor} from "./PartnersData";

export interface picturesURL {
  rawFile: object;
  src: Blob;
  title: string;
}

interface SponsorsData {
  company: string;
  image: string;
  urlweb: string;
  typeSponsor: string;
}

const PartnersMain = (props: any) => {
  const [dataUrl, setDataUrl] = useState<SponsorsData[] | []>([]);
  //const [imgUrl, setImgUrl] = useState<string[] | []>([]);

  useEffect(() => {
    // fetch("/sponsors")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log(data);
    //     setDataUrl(data);
    //     //console.log(imagePath + data[0].filename);
    //   });
  }, []);

  console.log(partnersData);

  const renderSponsor = (el: Sponsor) => {
    return (
      <a href={el.url} target="_blank"><img src={el.img} alt=""/></a>
    )
  }

  return (
    <section className="partners-main-page">
      <h1 className="partners-headline">PARTNERZY</h1>
      <div className="strategiczni">
        {partnersData.strategiczni.map(el => renderSponsor(el))}
      </div>
      <div className="glowni">
        {partnersData.glowni.map(el => renderSponsor(el))}
      </div>
      {/*<div className="zaufali">*/}
      {/*  {partnersData.zaufali.map(el => renderSponsor(el))}*/}
      {/*</div>*/}
  
      {/* <div className="partners-holder">
        {dataUrl &&
          dataUrl.map((el, i) => {
            if (el.typeSponsor == "strategiczny") {
              return <div className="partner-element">{el.company}</div>;
            }
          })}
      </div>
      <div className="partners-holder">
        {dataUrl &&
          dataUrl.map((el, i) => {
            if (el.typeSponsor == "glowny") {
              return <div className="partner-element">{el.company}</div>;
            }
          })}
      </div>
      <div className="partners-holder">
        {dataUrl &&
          dataUrl.map((el, i) => {
            if (el.typeSponsor == "zaufali") {
              return <div className="partner-element">{el.company}</div>;
            }
          })}
      </div> */}
    </section>
  );
};

export default PartnersMain;
