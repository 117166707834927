import Marcin_Platek from "../../images/Team/Sezon2025/Zarzad/Marcin_Platek.jpg"
import Magda_Sienska from "../../images/Team/Sezon2025/Zarzad/Magdalena_Sienska.jpg"
import Grzegorz_Radzikowski from "../../images/Team/Sezon2025/Zarzad/Grzegorz_Radzikowski.jpg"
interface Person {
    img?: any,
    name: string,
    description?: string,
	email?: string,
    leader?: boolean
}

interface Dzial {
    nameDzial: string,
    leader: Person,
    persons: Person[]
}

let kontakt_os: Person[] = [
	{img: Magda_Sienska, name:"Magdalena Sieńska", description: "Wiceprezes ds. organizacyjnych", email: " magdalena.sienska(at)wutracing.pl"},
	{img: Marcin_Platek, name:"Marcin Płatek", description:"Prezes", email: "marcin.platek(at)wutracing.pl"},
	{img: Grzegorz_Radzikowski, name: "Grzegorz Radzikowski", description:"Wiceprezes ds. technicznych", email: "grzegorz.radzikowski(at)wutracing.pl"}
]

export type {Dzial, Person};
export default {kontakt_os};