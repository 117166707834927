//PR
import	Michal_Wisniewski	from "../../../images/Team/Sezon2024/PR/Michał_Wiśniewski.jpg"
import  Adam_Krawczyk from "../../../images/Team/Sezon2024/PR/Adam_Krawczyk.jpg"
import  Kajetan_Boltralik from "../../../images/Team/Sezon2024/PR/Kajetan_Bołtralik.jpg"
import  ZK_Magda_Sienska from "../../../images/Team/Sezon2024/PR/ZK_Magda_Sieńska.jpg"

//Silnik		
import	Igor_Syska	from "../../../images/Team/Sezon2024/Silnik/Igor_Syska.jpg"
import	Jakub_Dzikowski	from "../../../images/Team/Sezon2024/Silnik/Jakub_Dzikowski.jpg"
import	Jakub_Florczak	from "../../../images/Team/Sezon2024/Silnik/Jakub_Florczak.jpg"
import	Mateusz_Makarewicz	from "../../../images/Team/Sezon2024/Silnik/Mateusz_Makarewicz.jpg"
import	Michal_Milaniuk	from "../../../images/Team/Sezon2024/Silnik/Michał_Milaniuk.jpg"
import	Mikolaj_Klonowski	from "../../../images/Team/Sezon2024/Silnik/Mikołaj_Klonowski.jpg"
import	ZK_Franciszek_Kajdzik	from "../../../images/Team/Sezon2024/Silnik/ZK_Franciszek_Kajdzik.jpg"
import	K_Kamil_Piszcz	from "../../../images/Team/Sezon2024/Silnik/K_Kamil_Piszcz.jpg"
import  Przemyslaw_Karolak from "../../../images/Team/Sezon2024/Silnik/Przemysław_Karolak.jpg"

//Chassis		
import	Aleksander_Abramowicz	from "../../../images/Team/Sezon2024/Chassis/Aleksander_Abramowicz.jpg"
import	Igor_Stepien	from "../../../images/Team/Sezon2024/Chassis/Igor_Stępień.jpg"
import	Jan_Czech	from "../../../images/Team/Sezon2024/Chassis/Jan_Czech.jpg"
import	ZK_Grzegorz_Spruch	from "../../../images/Team/Sezon2024/Chassis/ZK_Grzegorz_Spruch.jpg"
import  Filip_Polanski from "../../../images/Team/Sezon2024/Chassis/Filip_Polański.jpg"
import  K_Kamil_Gardyjas from "../../../images/Team/Sezon2024/Chassis/K_Kamil_Gardyjas.jpg"
import  Krzysztof_Zarnowski from "../../../images/Team/Sezon2024/Chassis/Krzysztof_Żarnowski.jpg"

//Zarząd		
import	Marcin_Platek	from "../../../images/Team/Sezon2024/Zarzad/Marcin_Płatek.jpg"
import  Kornelia_Kolodziejska from "../../../images/Team/Sezon2024/Zarzad/Kornelia_Kołodziejska.jpg"
import  Mateusz_Drabarek from "../../../images/Team/Sezon2024/Zarzad/Mateusz_Drabarek.jpg"
import  Piotr_Polakowski from "../../../images/Team/Sezon2024/Zarzad/Piotr_Polakowski.jpg"
import	Kamil_Polowczyk	from "../../../images/Team/Sezon2024/Kamil_Polowczyk.jpg"

//Zawias
import  Adam_Chilinski from "../../../images/Team/Sezon2024/Zawieszenie/Adam_Chiliński.jpg"
import	Andrzej_Graska	from "../../../images/Team/Sezon2024/Zawieszenie/Andrzej_Graska.jpg"
import	Grzegorz_Siedlecki	from "../../../images/Team/Sezon2024/Zawieszenie/Grzegorz_Siedlecki.jpg"
import	K_Tomasz_Kolakowski	from "../../../images/Team/Sezon2024/Zawieszenie/K_Tomasz_Kołakowski.jpg"
import	Maria_Sirko	from "../../../images/Team/Sezon2024/Zawieszenie/Maria_Sirko.jpg"
import	Mateusz_Krajewski	from "../../../images/Team/Sezon2024/Zawieszenie/Mateusz_Krajewski.jpg"
import	ZK_Ian_Gjellebol	from "../../../images/Team/Sezon2024/Zawieszenie/ZK_Ian_Gjellebol.jpg"
import  Maciej_Glen  from "../../../images/Team/Sezon2024/Zawieszenie/Maciej_Gleń.jpg"
import  Milosz_Moscicki from "../../../images/Team/Sezon2024/Zawieszenie/Miłosz_Mościcki.jpg"
import  Norbert_Kolek  from "../../../images/Team/Sezon2024/Zawieszenie/Norbert_Kołek.jpg"
import  Szymon_Drzewiecki from "../../../images/Team/Sezon2024/Zawieszenie/Szymon_Drzewiecki.jpg"

//Aero
import	Aleksandra_Sadowska	from "../../../images/Team/Sezon2024/Aero/Aleksandra_Sadowska.jpg"
import	Benedykt_Jez	from "../../../images/Team/Sezon2024/Aero/Benedykt_Jeż.jpg"
import	Iga_Gamska	from "../../../images/Team/Sezon2024/Aero/Iga_Gamska.jpg"
import	Lukasz_Rybakowski	from "../../../images/Team/Sezon2024/Aero/Łukasz_Rybakowski.jpg"
import	Michal_Zyto	from "../../../images/Team/Sezon2024/Aero/Michał_Żyto.jpg"
import	Wojciech_Baran	from "../../../images/Team/Sezon2024/Aero/Wojciech_Baran.jpg"
import	ZK_Tomasz_Polski	from "../../../images/Team/Sezon2024/Aero/ZK_Tomasz_Polski.jpg"
import  Jakub_Pacocha from "../../../images/Team/Sezon2024/Aero/Jakub_Pacocha.jpg"
import  Michal_Bartoszek from "../../../images/Team/Sezon2024/Aero/Michał_Bartoszek.jpg"
import  Rafal_Szulejko  from "../../../images/Team/Sezon2024/Aero/Rafał_Szulejko.jpg"
import  Wiktor_Gutowski from "../../../images/Team/Sezon2024/Aero/Wiktor_Gutowski.jpg"
import  K_Edwin_Podlewski from "../../../images/Team/Sezon2024/Aero/K_Edwin_Podlewski.jpg"
	
//Elektronika	
import	Blazej_Molas	from "../../../images/Team/Sezon2024/Elektronika/Błażej_Molas.jpg"
import	Jakub_Marcinowski	from "../../../images/Team/Sezon2024/Elektronika/Jakub_Marcinowski.jpg"
import	Kacper_Kowalski	from "../../../images/Team/Sezon2024/Elektronika/Kacper_Kowalski.jpg"
import	Patrycja_Wozniak	from "../../../images/Team/Sezon2024/Elektronika/Patrycja_Woźniak.jpg"
import  Filip_Jankowski from "../../../images/Team/Sezon2024/Elektronika/Filip_Jankowski.jpg"
import  Bartosz_Ciesla  from "../../../images/Team/Sezon2024/Elektronika/Bartek_Cieśla.jpg"
import  Jan_Sidor from "../../../images/Team/Sezon2024/Elektronika/Jan_Sidor.jpg"
import  K_Grzegorz_Radzikowski from "../../../images/Team/Sezon2024/Elektronika/K_Grzegorz_Radzikowski.jpg"
import  Marcin_Ciaston from "../../../images/Team/Sezon2024/Elektronika/Marcin_Ciastoń.jpg"
import  Michal_Kramkowski from "../../../images/Team/Sezon2024/Elektronika/Michał_Kramowski.jpg"

//Fundraising
import  Igor_Olczak from "../../../images/Team/Sezon2024/Fundraising/Igor_Olczak.jpg"
import  Maciej_Chibowski from "../../../images/Team/Sezon2024/Fundraising/Maciej_Chibowski.jpg"
import  Maja_Banasiak from "../../../images/Team/Sezon2024/Fundraising/Maja_Banasiak.jpg"
import  Mateusz_Czucha from "../../../images/Team/Sezon2024/Fundraising/Mateusz_Czucha.jpg"
import  Pawel_Polanowski from "../../../images/Team/Sezon2024/Fundraising/Paweł_Polanowski.jpg"

//Logistyka		
import	ZK_Mateusz_Giermek	from "../../../images/Team/Sezon2024/Logistyka/Mateusz_Giermek.jpg"
import	Szymon_Modzelewski	from "../../../images/Team/Sezon2024/Logistyka/Szymon_Modzelewski.jpg"
import  Filip_Langiewicz from "../../../images/Team/Sezon2024/Logistyka/Filip_Langiewicz.jpg"
import  Natalia_Dmytrak from "../../../images/Team/Sezon2024/Logistyka/Natalia_Dmytrak.jpg"
import  Zofia_Wiecha from "../../../images/Team/Sezon2024/Logistyka/Zofia_Wiecha.jpg"

import	brak	from "../../../images/Team/default.jpg"




interface Person {
    img?: any,
    name: string,
    description?: string,
    leader?: boolean
}

interface Dzial {
    nameDzial: string,
    leader: Person,
    persons: Person[]
}

let zarzad: Person[] = [
    {img: Marcin_Platek, name:"Marcin Płatek", description:"Prezes"},
    {img: Kornelia_Kolodziejska, name: "Kornelia Kołodziejska", description:"Wiceprezes ds. organizacyjnych"},
    {img: Piotr_Polakowski, name: "Piotr Polakowski", description:"Wiceprezes ds. finansowych"},
    {img: Mateusz_Drabarek, name: "Mateusz Drabarek", description:"Wiceprezes ds. technicznych"}
]


let aerodynamika: Dzial = {
    nameDzial: "DZIAŁ AERODYNAMIKI",
    leader: {img: K_Edwin_Podlewski, name: "Edvin Podlevski",description:"Koordynator działu Aerodynamiki"},
    persons: [
		{img: ZK_Tomasz_Polski, name: "Tomasz Polski", description: "Zastępca koordynatora"},
        {img: Wojciech_Baran, name: "Wojciech Baran"},
        {img: Michal_Bartoszek, name: "Michał Bartoszek"},
        {img: Benedykt_Jez, name: "Benedykt Jeż"},
        {img: Jakub_Pacocha, name: "Jakub Pacocha"},
        {img: Rafal_Szulejko, name: "Rafał Szulejko"},
        {img: Wiktor_Gutowski, name: "Wiktor Gutkowski"},
        {img: Iga_Gamska, name: "Iga Gamska"},
        {img: brak, name: "Miłosz Niedziela"},
        {img: Lukasz_Rybakowski, name: "Łukasz Rybakowski"},
        {img: Aleksandra_Sadowska, name: "Aleksandra Sadowska"},
        {img: ZK_Magda_Sienska, name: "Magdalena Sieńska"},
        {img: Michal_Zyto, name: "Michał Żyto"},
    ]
}

let chassis: Dzial = {
    nameDzial: "DZIAŁ CHASSIS",
    leader: {img: K_Kamil_Gardyjas, name: "Kamil Gardyjas", description: "Koordynator działu Chassis"},
    persons: [
		{img: ZK_Grzegorz_Spruch, name:"Grzegorz Spruch", description: "Zastępca koordynatora"},
        {img: Aleksander_Abramowicz, name: "Aleksander Abramowicz"},
        {img: Jan_Czech, name: "Jan Czech"},
        {img: Filip_Polanski, name: "Filip Polański"},
        {img: Krzysztof_Zarnowski, name: "Krzysztof Żarnowski"},
        {img: Igor_Stepien, name: "Igor Stępień"},
    ]
}

let zawieszenie: Dzial = {
    nameDzial: "DZIAŁ ZAWIESZENIA",
    leader: {img: K_Tomasz_Kolakowski, name:"Tomasz Kołakowski", description:"Koordynator działu zawieszenia"},
    persons: [
		{img: ZK_Ian_Gjellebol, name: "Ian Gjellebol", description: "Zastępca koordynatora"},
        {img: Norbert_Kolek, name: "Norbert Kołek"},
        {img: brak, name: "Miłosz Wnuk"},
        {img: Andrzej_Graska, name: "Andrzej Graska"},
        {img: Mateusz_Krajewski, name: "Mateusz Krajewski"},
        {img: Adam_Chilinski, name: "Adam Chiliński"},
        {img: Grzegorz_Siedlecki, name: "Grzegorz Siedlecki"},
        {img: Maria_Sirko, name: "Maria Sirko"},
        {img: Szymon_Drzewiecki, name: "Szymon Drzewiecki"},
        {img: Maciej_Glen, name: "Maciej Gleń"},
        {img: Milosz_Moscicki, name: "Milosz Moscicki"},
    ]
}

let elektronika: Dzial = {
    nameDzial: "DZIAŁ ELEKTRONIKI",
    leader: {img: K_Grzegorz_Radzikowski, name: "Grzegorz Radzikowski", description:"Koordynator działu elektroniki"},
    persons: [
        {img: Mateusz_Drabarek, name: "Mateusz Drabarek"},
        {img: Blazej_Molas, name: "Błażej Molas"},
        {img: Bartosz_Ciesla, name: "Bartosz Ciesla"},
		{img: Kacper_Kowalski, name: "Kacper Kowalski"},
        {img: Marcin_Ciaston, name: "Marcin Ciastoń"},
        {img: Jakub_Marcinowski, name: "Jakub Marcinowski"},
        {img: Filip_Jankowski, name: "Filip Jankowski"},
        {img: Michal_Kramkowski, name: "Michał Kramkowski"},
        {img: Jan_Sidor, name: "Jan_Sidor"},
        {img: Patrycja_Wozniak, name: "Patrycja Woźniak"},
        {img: brak, name: "Bartosz Lewandowski"},
    ]
}

let silnik: Dzial = {
    nameDzial: "DZIAŁ SILNIKA",
    leader: {img: K_Kamil_Piszcz, name: "Kamil Piszcz", description:"Koordynator działu silnika"},
    persons: [
		{img: ZK_Franciszek_Kajdzik, name: "Franciszek Kajdzik", description: "Zastępca koordynatora"},
        {img: Michal_Milaniuk, name: "Michał Milaniuk"},
        {img: Przemyslaw_Karolak, name: "Przemysław Karolak"},
        {img: Jakub_Dzikowski, name: "Jakub Dzikowski"},
        {img: Jakub_Florczak, name: "Jakub Florczak"},
        {img: brak, name: "Szymon Kosiński"},
        {img: Mikolaj_Klonowski, name: "Mikołaj Klonowski"},
        {img: Mateusz_Makarewicz, name: "Mateusz Makarewicz"},
        {img: Igor_Syska, name: "Igor Syska"},
        {img: brak, name: "Karol Zwoliński"},
    ]
}

let technicznyLider: Person = {img: Kamil_Polowczyk, name: "Kamil Polowczyk", description:"Lider działu technicznego"}
let operacyjnyLider: Person = {img: Kornelia_Kolodziejska, name: "Kornelia Kołodziejska", description:"Lider działu operacyjnego"}

let pr: Dzial = {
    nameDzial: "DZIAŁ PR",
    leader: {img: Kornelia_Kolodziejska, name: "Kornelia Kołodziejska", description: "Koordynator działu PR"},
    persons: [
        {img: ZK_Magda_Sienska, name: "Magdalena Sieńska", description: "Zastępca koordynatora"},
        {img: Adam_Krawczyk, name: "Adam Krawczyk"},
        {img: Michal_Wisniewski, name: "Michał Wiśniewski"},
        {img: Kajetan_Boltralik, name: "Kajetan Bołtralik"},
    ]
}

let logistyka: Dzial = {
    nameDzial: "DZIAŁ LOGISTYKI",
    leader: {img: Kornelia_Kolodziejska, name: "Kornelia Kołodziejska", description: "Koordynator działu logistyki"},
    persons: [
		{img: ZK_Mateusz_Giermek, name: "Mateusz Giermek", description: "Zastępca koordynatora"},
        {img: Szymon_Modzelewski, name: "Szymon Modzelewski"},
        {img: brak, name: "Aleksandra Breś"},
        {img: Filip_Langiewicz, name: "Filip Langiewicz"},
        {img: Natalia_Dmytrak, name: "Natalia Dmytrak"},
        {img: brak, name: "Michał Malankiewicz"},
    ]
}

let fundraising: Dzial = {
    nameDzial: "DZIAŁ FUNDRAISING",
    leader: {img: Zofia_Wiecha, name: "Zofia Wiecha", description: "Koordynator działu fundraisingu"},
    persons: [
        {img: Igor_Olczak, name: "Igor Olczak"},
        {img: Maciej_Chibowski, name: "Maciej Chibowski"},
        {img: Maja_Banasiak, name: "Maja Banasiak"},
        {img: Mateusz_Czucha, name: "Mateusz Czucha"},
        {img: Pawel_Polanowski, name: "Paweł Polanowski"},
    ]
}

export type {Dzial, Person};
export default {fundraising, logistyka, pr, silnik, elektronika, aerodynamika, chassis, zawieszenie, zarzad, operacyjnyLider, technicznyLider}
