import React from "react";
import { useCustomEffect } from "../../components/useCustomEffect";
import "../../styles/KontaktPage/KontaktPage.css";
import Footer from "../../components/Footer";
import data1, {Person} from "./KontaktOsoby"
//import data2 from "../TeamPage/TeamDataENG"
import {FiMail} from "react-icons/fi"

interface PropsDzialRender {
  header: string,
  dzial: Person[],
  className: string,
  leader?: Person,
  description?: React.ReactChild,
  email?: React.ReactChild
}

const renderDzial = (arg: PropsDzialRender) => {
    // console.log(data.zarzad)

    // const data = props.dzial t

    return (
      <div className={`dzial-container ${arg.className}`}>
        <div className={`header-container ${arg.leader ? "" : "no-leader"}`}><h1>{arg.header}</h1></div>
        {
          arg.leader &&
          <>
            <div className="person leader">
              <img src={arg.leader.img} alt=""/>
              <h2>{arg.leader.name}</h2>
              <h3>{arg.leader.description}</h3>
            </div>

            <div className="description">{arg.description}</div>
          </>
        }

        <div className="persons">
        {
          arg.dzial.map(el => (
            <div className="person">
              <img src={el.img} alt=""/>
              <h2>{el.name}</h2>
              <h3><span style={{color: "#e31922"}}>{el.description}</span><br/>
			  <FiMail/><br />
                  <span className="email-uncover" onClick={(e) => setText(e, el.email as string)}>Naciśnij aby odsłonić</span>
                  {/*{el.email}*/}
			  </h3>
            </div>
          ))
        }
        </div>
      </div>
    )
  }

const setText = (event: React.MouseEvent<HTMLSpanElement>, text: string) => {
    const spanElement = event.target as HTMLSpanElement;
    spanElement.textContent = text;
    spanElement.style.cursor = "default";
    spanElement.onclick = () => {};
}
const displayMails = () =>
{
    const zarzadElement = document.getElementById("zarzad-email") as HTMLSpanElement;
    const marketingElement = document.getElementById("marketing-email") as HTMLSpanElement;
    zarzadElement.textContent = "zarzad(at)wutracing.pl";
    zarzadElement.style.cursor = "default";
    zarzadElement.onclick = () => {};
    marketingElement.textContent = "marketing(at)wutracing.pl";
    marketingElement.style.cursor = "default";
    marketingElement.onclick = () => {};
}

const KontaktPage = (props: any) => {
  useCustomEffect(() => {}, []);

//const position = {lat: 52.22105026245117, lng: 21.005369186401367};

  return (
    <div className="kontakt-page">
      <div className="main-image">
        <h1>KONTAKT</h1>
      </div>
	  
	  <div className="kontakt-maile">
          <h3><FiMail/>
              &nbsp;<span id="zarzad-email" onClick={() => displayMails()}>Naciśnij aby odsłonić</span>
          </h3>
          <h3><FiMail/>
              &nbsp;<span id="marketing-email" onClick={() => displayMails()}>Naciśnij aby odsłonić</span>
          </h3>
      </div>
	  
	  {renderDzial({header: "KTO ODPOWIE NA TWOJE PYTANIE?", dzial: data1.kontakt_os, className:"zarzad_os"})}
	  
	  
	  <div className="map-container">
	  <div className="map-header-container"><h1>Gdzie nas znaleźć?</h1></div>
	 <iframe title="map" src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2444.234862976063!2d21.00262267721839!3d52.22095067198426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecceb9c8c28ab%3A0xe20138158892239e!2sWydzia%C5%82%20Mechaniczny%20Energetyki%20i%20Lotnictwa%20Politechniki%20Warszawskiej!5e0!3m2!1spl!2spl!4v1711204135023!5m2!1spl!2spl"}> </iframe>
	  <br/>
	  
	  {/*<APIProvider apiKey={'AIzaSyDIO58egtQ8CtbSd0Aq5VpfHku5BBcUZP4'}>
      <Map defaultCenter={position} defaultZoom={15}>
        <Marker position={position} />
      </Map>
		</APIProvider>*/}
	  </div>
	  
	  <Footer isEnglish={props.isEnglish} />
    </div>
	
  );
};

export default KontaktPage;
