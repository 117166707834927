import React from "react";
import { useCustomEffect } from "../../components/useCustomEffect";
import partnersData, {Sponsor} from "../MainPage/PartnersData";
import "../../styles/MainPage/PartnersMain.css";
import "../../styles/PartnersPage/PartnersPage.css";
import Footer from "../../components/Footer";
import PW_logo from "../../images/Sponsorzy/strategiczni/PW.png";

const PartnersPage = (props: any) => {
  useCustomEffect(() => {}, []);

  const renderSponsor = (el: Sponsor) => {
    return (
      <a href={el.url} target="_blank"><img src={el.img} alt=""/></a>
    )
  }

  return (<div>
  <div className="partners-main-page partners-page">
    <div className="partners-container">
    {/*  <h1 className="headline-partners">PARTNERZY STRATEGICZNI</h1> */}
	  <img src={PW_logo} alt="" className="PW_logo" />
      <div className="strategiczni">
        {partnersData.strategiczni.map(el => renderSponsor(el))}
      </div>
      <h1 className="headline-partners">PARTNERZY GŁÓWNI</h1>
      <div className="glowni">
          {partnersData.glowni.map(el => renderSponsor(el))}
        </div>
      {/*<h1 className="headline-partners">ZAUFALI NAM</h1>*/}
      {/*<div className="zaufali">*/}
      {/*  {partnersData.zaufali.map(el => renderSponsor(el))}*/}
      {/*</div>*/}
    </div>
	</div>
    <Footer isEnglish={props.isEnglish} />
	</div>
  )
};

export default PartnersPage;
