import React, { useEffect } from "react";
import "../../styles/MainPage/Manage.css";
import Srodek from "../../images/Team/Sezon2025/Aero/ZK_Iga_Gamska.jpg";
import Lewe from "../../images/Team/Sezon2025/Aero/Lukasz_Rybakowski.jpg";
import Prawe from "../../images/Team/Sezon2025/Logistyka/Antoni_Starczynowski.jpg";

import { useInView } from "react-intersection-observer";
import { useHistory } from "react-router-dom";

const ManageMain = (props: any) => {
  const history = useHistory();
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      let children = entry?.target.children;
      if (children) {
        let children0 = children[0] as HTMLElement;
        let children1 = children[1] as HTMLElement;
        let children2 = children[2] as HTMLElement;
        children0.style.opacity = "100%";

        setTimeout(() => {
          children1.style.opacity = "100%";
          setTimeout(() => {
            children2.style.opacity = "100%";
          }, 500);
        }, 500);
      }

      console.log(children);
    }
  }, [inView]);

  const handleClickButton = () => {
    history.push("/team");
  };

  return (
    <section className="manage-front-page">
      <div className="manage-container">
        <div className="manage-pictures" ref={ref}>
          <div className="holder-bent">
            <img src={Lewe} alt="Team member" />
          </div>
          <div className="holder-bent">
            <img src={Srodek} alt="Team member" />
          </div>
          <div className="holder-bent">
            <img src={Prawe} alt="Team member" />
          </div>
        </div>
        <div className="manage-text">
          <div className="text-headers">
            <h1>NASI</h1>
            <h1>CZŁONKOWIE</h1>
          </div>
          <button className="wut-button" onClick={handleClickButton}>
            STRUKTURA
          </button>
        </div>
      </div>
    </section>
  );
};

export default ManageMain;
