import React, { useState } from "react";
import { useCustomEffect } from "../../components/useCustomEffect";
import "../../styles/TeamPage/TeamPage.css";
// import "../../styles/MainPage/MainPage.css";
import data1, {Dzial, Person} from "./TeamData"
import data2 from "./TeamDataENG"
import Footer from "../../components/Footer";
import icon_up from "./angle-up-solid.svg";
import {useHistory} from "react-router-dom";

interface PropsDzialRender {
  header: string,
  dzial: Person[],
  className: string,
  leader?: Person,
  description?: React.ReactChild
}

interface PropsLiderRender {
  header: string,
  className: string,
  leader: Person,
}

interface descriptionDzial {
  aerodynamika: React.ReactChild,
  chassis: React.ReactChild,
  zawieszenie: React.ReactChild,
  elektronika: React.ReactChild,
  silnik: React.ReactChild,
  pr: React.ReactChild,
  logistyka: React.ReactChild,
  fundraising: React.ReactChild,
}

const descriptionDzial :descriptionDzial = {
  aerodynamika: <p><strong>Dział aerodynamiki WUT Racing</strong> przeprowadza niezbędne obliczenia, symulacje oraz optymalizację elementów nadwozia. Wdrażając nowe rozwiązania, sprawdzają ich wpływ na całość projektu i&nbsp;dobierają najlepsze z nich. Członkowie działu mają za zadanie także wykonanie elementów aerodynamicznych z materiałów kompozytowych.</p>,
  chassis: <p><strong>Dział chassis WUT Racing</strong> projektuje i buduje monocoque, a także ramę oraz pochłaniacz energii (Impact Attenuator). Przeprowadza analizę wytrzymałościową MES. Wytwarza elementy kompozytowe bolidu oraz bada ich próbki.</p>,
  zawieszenie: <p><strong>Dział zawieszenia WUT Racing </strong>przeprowadza obliczenia, symulacje oraz testy. Projektuje części w&nbsp;środowisku CAD, przygotowuje dokumentację techniczną oraz pomaga przy produkcji. Członkowie zajmują się liczeniem quasi-dynamiki pojazdu, tj. systemu amortyzacji i stabilizacji.</p>,
  elektronika: <p><strong>Dział elektroniki WUT Racing </strong>zajmuje się projektowaniem systemów elektrycznych i elektronicznych, których zadaniem jest zespolenie ze sobą elementów konstrukcji. Inżynierowie tworzą podzespoły odpowiadające za sterowanie pracą silnika wraz z układem przeniesienia napędu, a także za prawidłowe i bezawaryjne funkcjonowanie systemów wspomagających kierowcę. Dział ten zajmuje się również akwizycją danych, które później używane są w celu optymalnego wykorzystania potencjału bolidu.</p>,
  silnik: <p><strong>Dział silnika WUT Racing </strong>projektuje i modyfikuje poszczególne układy oraz ich elementy. Zajmuje się mapowaniem silnika dla różnych konkurencji, a także optymalizacją zużycia paliwa. Wśród zadań członków działu jest także rozwijanie stanowiska hamowni silnika.</p>,
  pr: <p><strong>Dział PR WUT Racing </strong>to wizytówka organizacji. Zajmuje się kreowaniem wizerunku WUT Racing. Koordynuje działania medialne oraz promocję wszelkich wydarzeń, w których team bierze udział.</p>,
  fundraising: <p><strong>Dział fundraisingu WUT Racing </strong> zajmuje się pozyskiwaniem partnerów wspierających działalność zespołu. Buduje i podtrzymuje relacje biznesowe z otoczeniem.</p>,
  logistyka: <p><strong>Dział logistyki WUT Racing </strong>zajmuje się organizacją aspektów logistycznych związanych z targami branżowymi oraz eventami, a także wyjazdów zagranicznych Koła. W zakresie obowiązków leżą także działania optymalizacyjne transportu członków i konstrukcji.</p>,

}

const TeamPage = (props: any) => {
  if(props.isEnglish) {
  var data = data2;}
  else {
  var data = data1;}

  const [activeDzial, setActiveDzial] = useState("wszyscy")

  useCustomEffect(() => {}, []);

  // const renderLider = (arg: PropsLiderRender) => {
  //    return (
  //      <div className={`dzial-container ${arg.className}`}>
  //        <div className={`header-container no-leader`}><h1>{arg.header}</h1></div>
  //          <div className="person leader">
  //            <img src={arg.leader.img} alt=""/>
  //            <h2>{arg.leader.name}</h2>
  //            <h3>{arg.leader.description}</h3>
  //          </div>
  //     </div>
  //    )
  // }

  const renderDzial = (arg: PropsDzialRender) => {
    // console.log(data.zarzad)

    // const data = props.dzial t

    return (
      <div className={`dzial-container ${arg.className}`}>
        <div className={`header-container ${arg.leader ? "" : "no-leader"}`}><h1>{arg.header}</h1></div>
        {
          arg.leader &&
          <>
            <div className="person leader">
              <img src={arg.leader.img} alt=""/>
              <h2>{arg.leader.name}</h2>
              <h3>{arg.leader.description}</h3>
            </div>

            <div className="description">{arg.description}</div>
          </>
        }

        <div className="persons">
        {
          arg.dzial.map(el => (
            <div className="person">
              <img src={el.img} alt=""/>
              <h2>{el.name}</h2>
              <h3>{el.description}</h3>
            </div>
          ))
        }
        </div>
      </div>
    )
  }

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
  }

  const changeViewDzial = (e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => {
    const element = e.target as HTMLButtonElement;
    const name = element.name

    // ustaw widok na przycisku
    const previousActive = document.getElementsByName(activeDzial)[0] as HTMLElement;
    previousActive.classList.remove("active")

    element.classList.add("active")
    setActiveDzial(name)

    const dzialy = document.getElementsByClassName("dzialy")[0] as HTMLDivElement

    if (name !== "wszyscy")
      dzialy.childNodes.forEach(el => {
        const childDiv = el as HTMLElement;
        childDiv.classList.add("dontshow")
        if (childDiv.classList[1] === name) {
          // ustaw na widoczne
          childDiv.classList.remove("dontshow")
        }
      })
    else 
      dzialy.childNodes.forEach(el => {
        const childDiv = el as HTMLElement;
        childDiv.classList.remove("dontshow")
      })
  }

  const history = useHistory();
  const mainTeamPage = () =>{
    history.push('/team/23-24')
  }

  return (
    <div className="team-page">
      <div className="main-image">
        <h1 id="Działy">ZESPÓŁ</h1>
      </div>
	  <div className="btnTop"><a href="#Działy"><img src={icon_up} alt="" /></a></div>

      <form className="form-pick-dzial" onSubmit={submitForm}>
        <button className="wut-button active" onClick={changeViewDzial} name="wszyscy">WSZYSCY</button>
        <button className="wut-button" onClick={changeViewDzial}  name="zarzad">ZARZĄD</button>
        <button className="wut-button" onClick={changeViewDzial} name="aerodynamika">AERODYNAMIKA</button>
        <button className="wut-button" onClick={changeViewDzial} name="chassis">CHASSIS</button>
        <button className="wut-button" onClick={changeViewDzial} name="zawieszenie">ZAWIESZENIE</button>
        <button className="wut-button" onClick={changeViewDzial} name="elektronika">ELEKTRONIKA</button>
        <button className="wut-button" onClick={changeViewDzial} name="silnik">SILNIK</button>
        <button className="wut-button" onClick={changeViewDzial} name="pr">PR</button>
        <button className="wut-button" onClick={changeViewDzial} name="logistyka">LOGISTYKA</button>
        <button className="wut-button" onClick={changeViewDzial} name="fundraising">FUNDRAISING</button>
      </form>
      <button className="redirect-button wut-button" name="previousTeam" onClick={mainTeamPage}>Zespół 23/24</button>

      <div className="dzialy">
      {renderDzial({header: "ZARZĄD", dzial: data.zarzad, className:"zarzad"})}
        {/*{renderLider({header: "LIDER DZIAŁU TECHNICZNEGO", className: "lider-techniczny", leader: data.technicznyLider})}*/}
      {renderDzial({header: "DZIAŁ AERODYNAMIKI", dzial: data.aerodynamika.persons, className:"aerodynamika", leader:data.aerodynamika.leader, description:descriptionDzial.aerodynamika})}
      {renderDzial({header: "DZIAŁ CHASSIS", dzial: data.chassis.persons, className:"chassis", leader:data.chassis.leader, description:descriptionDzial.chassis})}
      {renderDzial({header: "DZIAŁ ZAWIESZENIA", dzial: data.zawieszenie.persons, className:"zawieszenie", leader:data.zawieszenie.leader, description:descriptionDzial.zawieszenie})}
      {renderDzial({header: "DZIAŁ ELEKTRONIKI", dzial: data.elektronika.persons, className:"elektronika", leader:data.elektronika.leader, description:descriptionDzial.elektronika})}
      {renderDzial({header: "DZIAŁ SILNIKA", dzial: data.silnik.persons, className:"silnik", leader:data.silnik.leader, description:descriptionDzial.silnik})}
      {/*{renderLider({header: "LIDER DZIAŁU OPERACYJNEGO", className: "lider-techniczny", leader: data.operacyjnyLider})}*/}
      {renderDzial({header: "DZIAŁ PR", dzial: data.pr.persons, className:"pr", leader:data.pr.leader, description:descriptionDzial.pr})}
      {renderDzial({header: "DZIAŁ LOGISTYKI", dzial: data.logistyka.persons, className:"logistyka", leader:data.logistyka.leader, description:descriptionDzial.logistyka})}
      {renderDzial({header: "DZIAŁ FUNDRAISINGU", dzial: data.fundraising.persons, className:"fundraising", leader:data.fundraising.leader, description:descriptionDzial.fundraising})}
      </div>
      <Footer isEnglish={props.isEnglish} />
    </div>
  );
};

export default TeamPage;
