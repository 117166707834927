//PR
import	Michal_Wisniewski	from "../../images/Team/Sezon2025/PR/Michal_Wisniewski.jpg"
import  Dagmara_Legien from "../../images/Team/Sezon2025/PR/Dagmara_Legien.jpg"
import  Jakub_Kotowicz from "../../images/Team/Sezon2025/PR/Jakub_Kotowicz.jpg"

//Silnik		
import	Igor_Syska	from "../../images/Team/Sezon2025/Silnik/Igor_Syska.jpg"
//import	Jakub_Dzikowski	from "../../images/Team/Sezon2024/Silnik/Jakub_Dzikowski.jpg"
import	Jakub_Florczak	from "../../images/Team/Sezon2025/Silnik/Jakub_Florczak.jpg"
import	Mateusz_Makarewicz	from "../../images/Team/Sezon2025/Silnik/Mateusz_Makarewicz.jpg"
//import	K_Michal_Milaniuk	from "../../images/Team/Sezon2024/Silnik/Michał_Milaniuk.jpg"
import	Mikolaj_Klonowski	from "../../images/Team/Sezon2025/Silnik/Mikolaj_Klonowski.jpg"
//import	Franciszek_Kajdzik	from "../../images/Team/Sezon2024/Silnik/ZK_Franciszek_Kajdzik.jpg"
//import	ZK_Kamil_Piszcz	from "../../images/Team/Sezon2024/Silnik/K_Kamil_Piszcz.jpg"
import  Jakub_Czarzasty from "../../images/Team/Sezon2025/Silnik/Jakub_Czarzasty.jpg"
import  Kacper_Karpinski from "../../images/Team/Sezon2025/Silnik/Kacper_Karpinski.jpg"
import  Michal_Mazur from "../../images/Team/Sezon2025/Silnik/Michal_Mazur.jpg"

//Chassis		
//import	Aleksander_Abramowicz	from "../../images/Team/Sezon2024/Chassis/Aleksander_Abramowicz.jpg"
//import	Igor_Stepien	from "../../images/Team/Sezon2024/Chassis/Igor_Stępień.jpg"
//import	Jan_Czech	from "../../images/Team/Sezon2024/Chassis/Jan_Czech.jpg"
import	K_Grzegorz_Spruch	from "../../images/Team/Sezon2025/Chassis/K_Grzegorz_Spruch.jpg"
//import	ZK_Kamil_Polowczyk	from "../../images/Team/Sezon2024/Kamil_Polowczyk.jpg"
import  Krzysztof_Niebylski from "../../images/Team/Sezon2025/Chassis/Krzysztof_Niebylski.jpg"
import  Michal_Morawski from "../../images/Team/Sezon2025/Chassis/Michal_Morawski.jpg"
import  Mikolaj_Cieslak from "../../images/Team/Sezon2025/Chassis/Mikolaj_Cieslak.jpg"

//Zarząd		
import	Marcin_Platek	from "../../images/Team/Sezon2025/Zarzad/Marcin_Platek.jpg"
import	Magda_Sienska	from "../../images/Team/Sezon2025/Zarzad/Magdalena_Sienska.jpg"
import  Grzegorz_Radzikowski from "../../images/Team/Sezon2025/Zarzad/Grzegorz_Radzikowski.jpg"
import  Jakub_Pacocha from "../../images/Team/Sezon2025/Zarzad/Jakub_Pacocha.jpg"

//Zawias
import	Andrzej_Graska	from "../../images/Team/Sezon2025/Zawieszenie/Andrzej_Graska.jpg"
import	Grzegorz_Siedlecki	from "../../images/Team/Sezon2025/Zawieszenie/Grzegorz_Siedlecki.jpg"
import	K_Tomasz_Kolakowski	from "../../images/Team/Sezon2025/Zawieszenie/K_Tomasz_Kolakowski.jpg"
import	Maria_Sirko	from "../../images/Team/Sezon2025/Zawieszenie/Maria_Sirko.jpg"
import	Mateusz_Krajewski	from "../../images/Team/Sezon2025/Zawieszenie/Mateusz_Krajewski.jpg"
//import	ZK_Ian_Gjellebol	from "../../images/Team/Sezon2024/Zawieszenie/ZK_Ian_Gjellebol.jpg"
import  Damiar_Czerwiec from "../../images/Team/Sezon2025/Zawieszenie/Damian_Czerwiec.jpg"
import  Dominik_Werpachowski from "../../images/Team/Sezon2025/Zawieszenie/Dominik_Werpachowski.jpg"
import  Maksymilian_Wozniak from "../../images/Team/Sezon2025/Zawieszenie/Maksymilian_Wozniak.jpg"
import  Mieczyslaw_Pankiewicz from "../../images/Team/Sezon2025/Zawieszenie/Mieczyslaw_Pankiewicz.jpg"
import  Tamara_Saganek from "../../images/Team/Sezon2025/Zawieszenie/Tamara_Saganek.jpg"

//Aero
//import	Aleksandra_Sadowska	from "../../images/Team/Sezon2024/Aero/Aleksandra_Sadowska.jpg"
//import	Benedykt_Jez	from "../../images/Team/Sezon2024/Aero/Benedykt_Jeż.jpg"
import	ZK_Iga_Gamska	from "../../images/Team/Sezon2025/Aero/ZK_Iga_Gamska.jpg"
import	Lukasz_Rybakowski	from "../../images/Team/Sezon2025/Aero/Lukasz_Rybakowski.jpg"
//import	Michal_Zyto	from "../../images/Team/Sezon2024/Aero/Michał_Żyto.jpg"
//import	Wojciech_Baran	from "../../images/Team/Sezon2024/Aero/Wojciech_Baran.jpg"
import	K_Tomasz_Polski	from "../../images/Team/Sezon2025/Aero/K_Tomasz_Polski.jpg"
import  Jakub_Krajewski from "../../images/Team/Sezon2025/Aero/Jakub_Krajewski.jpg"
import  Kamil_Grabowski from "../../images/Team/Sezon2025/Aero/Kamil_Grabowski.jpg"
import  Milosz_Niedziela from "../../images/Team/Sezon2025/Aero/Milosz_Niedziela.jpg"
import  Stefan_Jozefowicz_Okonkwo from "../../images/Team/Sezon2025/Aero/Stefan_Jozefowicz-Okonkwo.jpg"
	
//Elektronika	
import	K_Blazej_Molas	from "../../images/Team/Sezon2025/Elektronika/K_Blazej_Molas.jpg"
import	Jakub_Marcinowski	from "../../images/Team/Sezon2025/Elektronika/Jakub_Marcinowski.jpg"
import	ZK_Kacper_Kowalski	from "../../images/Team/Sezon2025/Elektronika/ZK_Kacper_Kowalski.jpg"
//import	Patrycja_Wozniak	from "../../images/Team/Sezon2024/Elektronika/Patrycja_Woźniak.jpg"
import	Milosz_Moscicki	from "../../images/Team/Sezon2025/Elektronika/Milosz_Moscicki.jpg"
//import	Mateusz_Drabarek	from "../../images/Team/Sezon2024/Zarzad/Mateusz_Drabarek.jpg"
import  Bartosz_Pietrzak from "../../images/Team/Sezon2025/Elektronika/Bartosz_Pietrzak.jpg"
import  Katarzyna_Matejuk from "../../images/Team/Sezon2025/Elektronika/Katarzyna_Matejuk.jpg"

//Fundraising
import  Karolina_Zawadzka from "../../images/Team/Sezon2025/Fundraising/Karolina_Zawadzka.jpg"

//Logistyka		
import	K_Mateusz_Giermek	from "../../images/Team/Sezon2025/Logistyka/K_Mateusz_Giermek.jpg"
import	ZK_Szymon_Modzelewski	from "../../images/Team/Sezon2025/Logistyka/ZK_Szymon_Modzelewski.jpg"
import  Aleksandra_Bres from "../../images/Team/Sezon2025/Logistyka/Aleksandra_Bres.jpg"
import  Antoni_Starczynowski from "../../images/Team/Sezon2025/Logistyka/Antoni_Starczynowski.jpg"
import  Maciej_Stradowski from "../../images/Team/Sezon2025/Logistyka/Maciej_Stradowski.jpg"

import	brak	from "../../images/Team/default.jpg"




interface Person {
    img?: any,
    name: string,
    description?: string,
    leader?: boolean
}

interface Dzial {
    nameDzial: string,
    leader: Person,
    persons: Person[]
}

let zarzad: Person[] = [
    {img: Marcin_Platek, name:"Marcin Płatek", description:"Prezes"},
    {img: Magda_Sienska, name: "Magdalena Sieńska", description:"Wiceprezes ds. organizacyjnych"},
    {img: Jakub_Pacocha, name: "Jakub Pacocha", description:"Wiceprezes ds. finansowych"},
    {img: Grzegorz_Radzikowski, name: "Grzegorz Radzikowski", description:"Wiceprezes ds. technicznych"}
]


let aerodynamika: Dzial = {
    nameDzial: "DZIAŁ AERODYNAMIKI",
    leader: {img: K_Tomasz_Polski, name: "Tomasz Polski",description:"Koordynator działu Aerodynamiki"},
    persons: [
		{img: ZK_Iga_Gamska, name: "Iga Gamska", description: "Zastępca koordynatora"},
    {img: brak, name: "Wojciech Baran"},
    {img: Kamil_Grabowski, name: "Kamil Grabowski"},
    {img: brak, name: "Benedykt Jeż"},
    {img: Stefan_Jozefowicz_Okonkwo, name: "Stefan Józefowicz-Okonkwo"},
    {img: Jakub_Krajewski, name: "Jakub Krajewski"},
    {img: Milosz_Niedziela, name: "Miłosz Niedziela"},
    {img: brak, name: "Szymon Rosiński"},
    {img: Lukasz_Rybakowski, name: "Łukasz Rybakowski"},
    {img: brak, name: "Aleksandra Sadowska"},
    {img: Magda_Sienska, name: "Magdalena Sieńska"},
    {img: brak, name: "Michał Żyto"},
    ]
}

let chassis: Dzial = {
    nameDzial: "DZIAŁ CHASSIS",
    leader: {img: K_Grzegorz_Spruch, name: "Grzegorz Spruch", description: "Koordynator działu Chassis"},
    persons: [
		{img: brak, name:"Kamil Polowczyk", description: "Zastępca koordynatora"},
    {img: brak, name: "Aleksander Abramowicz"},
    {img: Mikolaj_Cieslak, name: "Mikołaj Cieślak"},
    {img: brak, name: "Jan Czech"},
    {img: brak, name: "Bartłomiej Misiurski"},
    {img: Michal_Morawski, name: "Michał Morawski"},
    {img: Krzysztof_Niebylski, name: "Krzysztof Niebylski"},
    {img: brak, name: "Igor Stępień"},
    ]
}

let zawieszenie: Dzial = {
    nameDzial: "DZIAŁ ZAWIESZENIA",
    leader: {img: K_Tomasz_Kolakowski, name:"Tomasz Kołakowski", description:"Koordynator działu zawieszenia"},
    persons: [
		{img: brak, name: "Ian Gjellebol", description: "Zastępca koordynatora"},
    {img: Damiar_Czerwiec, name: "Damian Czerwiec"},
        {img: Tamara_Saganek, name: "Tamara Saganek"},
    {img: Andrzej_Graska, name: "Andrzej Graska"},
    {img: Mateusz_Krajewski, name: "Mateusz Krajewski"},
    {img: Mieczyslaw_Pankiewicz, name: "Mieczysław Pankiewicz"},
    {img: Grzegorz_Siedlecki, name: "Grzegorz Siedlecki"},
    {img: Maria_Sirko, name: "Maria Sirko"},
    {img: Maksymilian_Wozniak, name: "Maksymilian Woźniak"},
        {img: Dominik_Werpachowski, name: "Dominik Werpachowski"},
    ]
}

let elektronika: Dzial = {
    nameDzial: "DZIAŁ ELEKTRONIKI",
    leader: {img: K_Blazej_Molas, name: "Błażej Molas", description:"Koordynator działu elektroniki"},
    persons: [
		{img: ZK_Kacper_Kowalski, name: "Kacper Kowalski", description: "Zastępca koordynatora"},
        {img: Grzegorz_Radzikowski, name: "Grzegorz Radzikowski"},
    {img: brak, name: "Mateusz Drabarek"},
    {img: Jakub_Marcinowski, name: "Jakub Marcinowski"},
    {img: Katarzyna_Matejuk, name: "Katarzyna Matejuk"},
        {img: Milosz_Moscicki, name: "Miłosz Mościcki"},
    {img: Bartosz_Pietrzak, name: "Bartosz Pietrzak"},
        {img: brak, name: "Karolina Ratajczyk"},
    {img: brak, name: "Patrycja Woźniak"},
    ]
}

let silnik: Dzial = {
    nameDzial: "DZIAŁ SILNIKA",
    leader: {img: brak, name: "Michał Milaniuk", description:"Koordynator działu silnika"},
    persons: [
		{img: brak, name: "Kamil Piszcz", description: "Zastępca koordynatora"},
    {img: Jakub_Czarzasty, name: "Jakub Czarzasty"},
    {img: brak, name: "Jakub Dzikowski"},
    {img: Jakub_Florczak, name: "Jakub Florczak"},
    {img: brak, name: "Franciszek Kajdzik"},
    {img: Kacper_Karpinski, name: "Kacper Karpiński"},
    {img: Mikolaj_Klonowski, name: "Mikołaj Klonowski"},
        {img: brak, name: "Wiktor Leyko"},
    {img: Mateusz_Makarewicz, name: "Mateusz Makarewicz"},
    {img: Michal_Mazur, name: "Michał Mazur"},
    {img: Igor_Syska, name: "Igor Syska"},
    {img: brak, name: "Marcel Szpanel"},
    ]
}

let operacyjnyLider: Person = {img: Magda_Sienska, name: "Magdalena Sieńska", description:"Lider działu operacyjnego"}

let pr: Dzial = {
    nameDzial: "DZIAŁ PR",
    leader: {img: Magda_Sienska, name: "Magdalena Sieńska", description: "Koordynator działu PR"},
    persons: [
      {img: Jakub_Pacocha, name: "Jakub Pacocha", description: "Zastępca koordynatora"},
      {img: brak, name: "Beniamin Buchman"},
      {img: Jakub_Kotowicz, name: "Jakub Kotowicz"},
      {img: Dagmara_Legien, name: "Dagmara Legień"},
      {img: Michal_Wisniewski, name: "Michał Wiśniewski"},
      {img: brak, name: "Patrycja Wójcik"},
    ]
}

let logistyka: Dzial = {
    nameDzial: "DZIAŁ LOGISTYKI",
    leader: {img: K_Mateusz_Giermek, name: "Mateusz Giermek", description: "Koordynator działu logistyki"},
    persons: [
		{img: ZK_Szymon_Modzelewski, name: "Szymon Modzelewski", description: "Zastępca koordynatora"},
    {img: Aleksandra_Bres, name: "Aleksandra Breś"},
    {img: Antoni_Starczynowski, name: "Antoni Starczynowski"},
    {img: Maciej_Stradowski, name: "Maciej Stradowski"},
    ]
}

let fundraising: Dzial = {
    nameDzial: "DZIAŁ FUNDRAISING",
    leader: {img: Jakub_Pacocha, name: "Jakub Pacocha", description: "Koordynator działu fundraisingu"},
    persons: [
    {img: Magda_Sienska, name: "Magdalena Sieńska", description: "Zastępca koordynatora"},
    {img: brak, name: "Łukasz Cwalina"},
    {img: Karolina_Zawadzka, name: "Karolina Zawadzka"},
    ]
}

export type {Dzial, Person};
export default {fundraising, logistyka, pr, silnik, elektronika, aerodynamika, chassis, zawieszenie, zarzad, operacyjnyLider}
